import { BOI_WALLET_USER_STORAGE } from '../constants';
import { routes } from '../routes';
import { getItemFromSessionStorage } from './sessionStorageHelper';
import { getAccessToken } from './UserAccessHelper';

const formatPayload = payload => {
  return payload ? { body: JSON.stringify(payload) } : {};
};

const setAuthorizationToken = authToken => {
  return authToken ? { Authorization: `Bearer ${authToken}` } : {};
};

const redirectToLoginPage = (history = {}) => {
  if (typeof history.push === 'function') {
    history.push(routes.loginPage.url);
  }
};

const redirectToHomePageWithCacheRefresh = () => {
  const baseUrl = `${process.env.PUBLIC_URL}/`;
  window.location.replace(baseUrl);
};

const checkResponseForBody = (response, onError) => {
  try {
    const res = response.json();
    return res.then(
      res => res,
      error => {
        if (onError) {
          onError();
        }
        return { error: true, errorMessage: error.message };
      }
    );
  } catch (error) {
    if (onError) {
      onError();
    }
    return new Promise(res =>
      res({ error: true, errorMessage: error.message })
    );
  }
};

export const makeRequest = async ({
  url,
  method,
  payload,
  onError,
  settingsOverride = {},
  history = {}
}) => {
  const cognitoAccessTokenKey = getAccessToken(
    JSON.parse(getItemFromSessionStorage(BOI_WALLET_USER_STORAGE))
  );
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const settings = {
    method: method,
    headers: {
      Accept: 'application/json',
      ...setAuthorizationToken(authToken),
      'Content-Type': 'application/json',
      'x-custom-ui-version': 'release/1.27.7/5'
    },
    ...formatPayload(payload)
  };
  const finalSettings = { ...settings, ...settingsOverride };
  return fetch(url, finalSettings).then(
    response => {
      if (response.status === 412) {
        // response 412 means there is a mismatch between ui version and backend version
        redirectToHomePageWithCacheRefresh();
      }
      if (response.status === 401) {
        // The user got an unauthorized call and therefore needs login
        redirectToLoginPage(history);
      }
      return checkResponseForBody(response, onError);
    },
    error => {
      if (onError) {
        onError();
      }
      return new Promise(res =>
        res({ error: true, errorMessage: error.message })
      );
    }
  );
};

export const sendAnalyticsEvent = async (quotePromise, event, label) => {
  if (quotePromise && !quotePromise.error) {
    let quote;

    Array.isArray(quotePromise)
      ? (quote = quotePromise[0])
      : (quote = quotePromise);

    window.dataLayer.push({
      event: `${event}`,
      eventLabel: `${label} Insurance quote`,
      quoteNum: `${quote.quote_reference}`
    });
  }
};
